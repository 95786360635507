import "jspdf-autotable"
import { jsPDF } from "jspdf"
import Loading from "@/components/widgets/Loading.vue"

export default {
  data: function () {
    return {
      role: "",
      branch: "",
      allResult: null,
      loading: false,
      document: {
        no: "",
        date: "",
        note: ''
      },
      products: []
    }
  },
  components: {
    Loading
  },
  mounted() {
    this.$nextTick(() => {
      this.onInitData()
      this.getRole()
    });
  },
  methods: {
    async getRole(){
      const res = await this.$store.dispatch('Auth/getRole', {}, { root: true })
      this.role = res.data.role
      this.branch = res.data.branch_id
    },
    async onInitIFrame() {
      this.loading = true
      setTimeout(async () => {
        const processJspdf = async () => {
          return new Promise(async (resolve, reject) => {
            const doc = new jsPDF("p", "mm", "a4")
            doc.addFileToVFS(
              "THSarabunNew.ttf",
              // ttf font file converted to base64
              // following is Consolas with only hex digit glyphs defined (0-9, A-F)
              this.thSarabunNormal
            );
            // add custom font to file
            doc.addFont("THSarabunNew.ttf", "THSarabunNew", "normal")
            doc.setFont("THSarabunNew", "normal")

            doc.addFileToVFS(
              "THSarabunNew Bold.ttf",
              // ttf font file converted to base64
              // following is Consolas with only hex digit glyphs defined (0-9, A-F)
              this.thSarabunBold
            );
            // add custom font to file
            doc.addFont("THSarabunNew Bold.ttf", "THSarabunNew", "bold")
            doc.setFont("THSarabunNew", "bold")

            const head = await this.onInitHeader()
            doc.autoTable({
              theme: "plain",
              margin: { top: 8, bottom: 0, left: 8, right: 8 },
              styles: {
                font: "THSarabunNew",
              },
              headStyles: {
                valign: "top",
                minCellHeight: 0,
              },
              bodyStyles: {
                valign: "top",
                minCellHeight: 0,
              },
              footStyles: {
                valign: "top",
                halign: "center",
                minCellHeight: 0,
              },
              showFoot: "lastPage",
              head: head,
              body: [],
              willDrawCell(data) {
                if (data.cell.raw && data.section === "head") {
                  if (data.cell.raw.content === "breaklineheader") {
                    const width = doc.internal.pageSize.getWidth() - 16;
                    data.doc.setLineWidth(0.2);
                    data.doc.setDrawColor(220, 220, 220);
                    data.doc.line(
                      data.cell.x,
                      data.cell.y + 5,
                      data.cell.x + width,
                      data.cell.y + 5
                    )
                  }
                }
              }
            })

            const headTable = await this.onInitHeaderTable()
            const body = await this.onInitBody()
            doc.autoTable({
              theme: "plain",
              margin: { top: 8, bottom: 8, left: 8, right: 8 },
              startY: doc.lastAutoTable.finalY + 6,
              styles: {
                font: "THSarabunNew",
              },
              headStyles: {
                valign: "top",
                minCellHeight: 0,
              },
              bodyStyles: {
                valign: "top",
                minCellHeight: 0,
              },
              footStyles: {
                valign: "top",
                halign: "center",
                minCellHeight: 0,
              },
              head: headTable,
              body: body
            })
            resolve(doc)
          })
        }

        const doc = await processJspdf()
        let element = document.getElementById("request-document-file")
        if (element) {
          element.setAttribute("src", doc.output("bloburl"))
          setTimeout(() => {
            this.loading = false;
            this.onIframePrint();
          }, 1000);
        }
      }, 1000)
    },
    onIframePrint() {
      let iFrame = document.getElementById("request-document-file");
      iFrame.focus();
      iFrame.contentWindow.print();
    },
    async onInitData() {
      this.busy = true
      this.loading = true
      try {
        const param = {
          _id: this.$route.params.id,
        }
        const result = await this.$store.dispatch(
          "Documents/getRequestDocument",
          param,
          { root: true }
        );
        this.busy = false

        this.allResult = result.data
        let res = result.data.data

        let document_no = "RO-" + res.document_no;
        let date = res.date;

        this.document.no = document_no
        this.document.date = date
        this.document.note = res?.note

        this.products = result.data.products
        this.loading = false
      } catch (error) {
        this.loading = false
        this.busy = false
        this.onExceptionHandler()        
        return []
      }
    },
    onInitHeaderTable() {
      return new Promise(async (resolve, reject) => {
        const head = [
          [
            {
              content: "ลำดับ",
              styles: {
                lineWidth: 0.1,
                fillColor: [243, 244, 246],
                cellPadding: 2,
                cellWidth: 16,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: "normal",
                halign: "center",
                valign: "top"
              }
            },
            {
              content: "สี",
              styles: {
                lineWidth: 0.1,
                fillColor: [243, 244, 246],
                cellPadding: 2,
                cellWidth: 35,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: "normal",
                halign: "center",
                valign: "top"
              }
            },
            {
              content: "ความหนา",
              styles: {
                lineWidth: 0.1,
                fillColor: [243, 244, 246],
                cellPadding: 2,
                cellWidth: 27,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: "normal",
                halign: "center",
                valign: "top"
              }
            },
            {
              content: "ยี่ห้อ",
              styles: {
                lineWidth: 0.1,
                fillColor: [243, 244, 246],
                cellPadding: 2,
                cellWidth: 27,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: "normal",
                halign: "center",
                valign: "top"
              }
            },
            {
              content: "จำนวน",
              styles: {
                lineWidth: 0.1,
                fillColor: [243, 244, 246],
                cellPadding: 2,
                cellWidth: 15,
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: "normal",
                halign: "center",
                valign: "top"
              }
            },
            {
              content: "หมายเหตุ",
              styles: {
                lineWidth: 0.1,
                fillColor: [243, 244, 246],
                cellPadding: 2,
                cellWidth: "auto",
                fontSize: 15,
                textColor: [15, 23, 42],
                fontStyle: "normal",
                halign: "center",
                valign: "top"
              }
            }
          ]
        ]
        resolve(head)
      })
    },
    onInitHeader() {
      return new Promise(async (resolve, reject) => {
        const head = [
          [
            {
              content: "ใบคำขอ",
              colSpan: 6,
              styles: {
                lineWidth: 0,
                cellWidth: "auto",
                fontSize: 26,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: "bold",
                halign: "left",
                valign: "middle",
              },
            }
          ],
          [
            {
              content: "Request Order",
              colSpan: 6,
              styles: {
                lineWidth: 0,
                cellWidth: "auto",
                fontSize: 22,
                textColor: [15, 23, 42],
                cellPadding: {
                  top: -1,
                  bottom: 3,
                },
                fontStyle: "normal",
                halign: "left",
                valign: "middle",
              },
            },
          ]
        ]
        const branchs = this.allResult.branches
        for (let index = 0; index < branchs.length; index++) {
          const element = branchs[index]
          if (index === 0) {
            let object1 = {}
            let object2 = {}
            let object3 = {}

            object1 = [
                {
                  content: `รหัสสาขา ${element.detail.code}`,
                  colSpan: 4,
                  styles: {
                    lineWidth: 0,
                    cellWidth: "auto",
                    fontSize: 15,
                    textColor: [15, 23, 42],
                    cellPadding: 0,
                    fontStyle: "normal",
                    halign: "left",
                    valign: "middle",
                  },
                },
                {
                  content: "เลขที่เอกสาร:",
                  styles: {
                    lineWidth: 0,
                    cellWidth: 40,
                    fontSize: 15,
                    textColor: [15, 23, 42],
                    cellPadding: 0,
                    fontStyle: "normal",
                    halign: "right",
                    valign: "middle"
                  }
                },
                {
                  content: this.document.no,
                  styles: {
                    lineWidth: 0,
                    cellWidth: 40,
                    fontSize: 15,
                    textColor: [15, 23, 42],
                    cellPadding: 0,
                    fontStyle: "normal",
                    halign: "right",
                    valign: "middle"
                  }
                }
            ]
            object2 = [
              {
                content: `${element.detail.name}`,
                colSpan: 4,
                styles: {
                  lineWidth: 0,
                  cellWidth: "auto",
                  fontSize: 15,
                  textColor: [15, 23, 42],
                  cellPadding: 0,
                  fontStyle: "normal",
                  halign: "left",
                  valign: "middle"
                }
              },
              {
                content: "วันที่เอกสาร:",
                styles: {
                  lineWidth: 0,
                  cellWidth: 40,
                  fontSize: 15,
                  textColor: [15, 23, 42],
                  cellPadding: 0,
                  fontStyle: "normal",
                  halign: "right",
                  valign: "middle"
                }
              },
              {
                content: new Date(this.document.date).toLocaleDateString(
                  "th-TH",
                  {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  }
                ),
                styles: {
                  lineWidth: 0,
                  cellWidth: 40,
                  fontSize: 15,
                  textColor: [15, 23, 42],
                  cellPadding: 0,
                  fontStyle: "normal",
                  halign: "right",
                  valign: "middle"
                }
              }
            ]
            object3 = [
              {
                content: `${(element.detail.address).replace(/[\n\t\r ]+/g, ' ').trim()}`,
                colSpan: 6,
                styles: {
                  lineWidth: 0,
                  cellWidth: "auto",
                  fontSize: 15,
                  textColor: [15, 23, 42],
                  cellPadding: {
                    bottom: 2
                  },
                  fontStyle: "normal",
                  halign: "left",
                  valign: "middle"
                }
              }
            ]
            
            head.push(object1)
            head.push(object2)
            head.push(object3)
          } else {
            let object1 = {}
            let object2 = {}
            let object3 = {}
            
            object1 = [
              {
                content: `รหัสสาขา ${element.detail.code}`,
                colSpan: 6,
                styles: {
                  lineWidth: 0,
                  cellWidth: "auto",
                  fontSize: 15,
                  textColor: [15, 23, 42],
                  cellPadding: 0,
                  fontStyle: "normal",
                  halign: "left",
                  valign: "middle",
                },
              }
            ]
            object2 = [
              {
                content: `${element.detail.name}`,
                colSpan: 6,
                styles: {
                  lineWidth: 0,
                  cellWidth: "auto",
                  fontSize: 15,
                  textColor: [15, 23, 42],
                  cellPadding: 0,
                  fontStyle: "normal",
                  halign: "left",
                  valign: "middle"
                }
              }
            ]
            object3 = [
              {
                content: `${(element.detail.address).replace(/[\n\t\r ]+/g, ' ').trim()}`,
                colSpan: 6,
                styles: {
                  lineWidth: 0,
                  cellWidth: "auto",
                  fontSize: 15,
                  textColor: [15, 23, 42],
                  cellPadding: {
                    bottom: 2
                  },
                  fontStyle: "normal",
                  halign: "left",
                  valign: "middle"
                }
              }
            ]

            head.push(object1)
            head.push(object2)
            head.push(object3)
          }
        }
        head.push(
          [
            {
              content: `หมายเหตุ: ${this.document.note}`,
              colSpan: 6,
              styles: {
                lineWidth: 0,
                cellWidth: "auto",
                fontSize: 15,
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: "normal",
                halign: "left",
                valign: "middle"
              }
            }
          ],
          [
            {
              content: "breaklineheader",
              colSpan: 6,
              styles: {
                lineWidth: 0,
                cellWidth: "auto",
                fontSize: 15,
                textColor: [255, 255, 255],
                cellPadding: 0,
                fontStyle: "bold",
                halign: "center",
                valign: "middle"
              },
            }
          ]
        )
        resolve(head)
      })
    },
    onInitBody() {
      return new Promise(async (resolve, reject) => {
        const data = this.products
        const body = []
        for (let index = 0; index < data.length; index++) {
          const element = data[index]
          let obj = {}
          obj = [
            {
              content: (index + 1).toString(),
              styles: {
                cellWidth: 16,
                lineWidth: {
                  left: 0.1,
                  right: 0.1,
                },
                fontSize: 15,
                fillColor: [255, 255, 255],
                textColor: [15, 23, 42],
                cellPadding: 0,
                fontStyle: "normal",
                halign: "center",
                valign: "top",
              }
            },
            {
              content: element.color,
              styles: {
                cellWidth: 27,
                lineWidth: {
                  left: 0.1,
                  right: 0.1,
                },
                fontSize: 15,
                fillColor: [255, 255, 255],
                textColor: [15, 23, 42],
                cellPadding: {
                  left: 2,
                  right: 2,
                  top: 1,
                  bottom: 1,
                },
                fontStyle: "normal",
                halign: "left",
                valign: "top",
              }
            },
            {
              content: element.thickness,
              styles: {
                lineWidth: {
                  left: 0.1,
                  right: 0.1,
                },
                cellWidth: 27,
                fontSize: 15,
                fillColor: [255, 255, 255],
                textColor: [15, 23, 42],
                cellPadding: {
                  left: 2,
                  right: 2,
                  top: 1,
                  bottom: 1,
                },
                fontStyle: "normal",
                halign: "left",
                valign: "top",
              }
            },
            {
              content: element.brand,
              styles: {
                lineWidth: {
                  left: 0.1,
                  right: 0.1,
                },
                cellWidth: 27,
                fontSize: 15,
                fillColor: [255, 255, 255],
                textColor: [15, 23, 42],
                cellPadding: {
                  left: 2,
                  right: 2,
                  top: 1,
                  bottom: 1,
                },
                fontStyle: "normal",
                halign: "left",
                valign: "top",
              }
            },
            {
              content: element.quantity,
              styles: {
                lineWidth: {
                  left: 0.1,
                  right: 0.1,
                },
                fontSize: 15,
                cellWidth:27,
                fillColor: [255, 255, 255],
                textColor: [15, 23, 42],
                cellPadding: {
                  left: 2,
                  right: 2,
                  top: 1,
                  bottom: 1,
                },
                fontStyle: "normal",
                halign: "right",
                valign: "top",
              }
            },
            {
              content: element.note,
              styles: {
                lineWidth: {
                  left: 0.1,
                  right: 0.1,
                },
                fontSize: 15,
                cellWidth: 'auto',
                fillColor: [255, 255, 255],
                textColor: [15, 23, 42],
                cellPadding: {
                  left: 2,
                  right: 2,
                  top: 1,
                  bottom: 1,
                },
                fontStyle: "normal",
                halign: "left",
                valign: "top",
              }
            }
          ]
          body.push(obj)
        }

        let emptyBody = {}
        emptyBody = [
          {
            content: "",
            styles: {
              cellWidth: 16,
              lineWidth: {
                left: 0.1,
                right: 0.1,
              },
              fontSize: 15,
              fillColor: [255, 255, 255],
              textColor: [15, 23, 42],
              cellPadding: {
                left: 2,
                right: 2,
                top: 1,
                bottom: 1,
              },
              fontStyle: "normal",
              halign: "center",
              valign: "middle"
            }
          },
          {
            content: "",
            styles: {
              cellWidth: 27,
              lineWidth: {
                left: 0.1,
                right: 0.1,
              },
              fontSize: 15,
              fillColor: [255, 255, 255],
              textColor: [15, 23, 42],
              cellPadding: {
                left: 2,
                right: 2,
                top: 1,
                bottom: 1,
              },
              fontStyle: "normal",
              halign: "center",
              valign: "middle"
            }
          },
          {
            content: "",
            styles: {
              lineWidth: {
                left: 0.1,
                right: 0.1,
              },
              fillColor: [255, 255, 255],
              cellPadding: {
                left: 2,
                right: 2,
                top: 1,
                bottom: 1,
              },
              cellWidth: 27,
              fontSize: 15,
              textColor: [15, 23, 42],
              fontStyle: "normal",
              halign: "left",
              valign: "middle"
            }
          },
          {
            content: "",
            styles: {
              lineWidth: {
                left: 0.1,
                right: 0.1,
              },
              fillColor: [255, 255, 255],
              cellPadding: {
                left: 2,
                right: 2,
                top: 1,
                bottom: 1,
              },
              cellWidth: 27,
              fontSize: 15,
              textColor: [15, 23, 42],
              fontStyle: "normal",
              halign: "right",
              valign: "top"
            }
          },
          {
            content: "",
            styles: {
              lineWidth: {
                left: 0.1,
                right: 0.1,
              },
              cellPadding: {
                left: 2,
                right: 2,
                top: 1,
                bottom: 1,
              },
              fillColor: [255, 255, 255],
              cellWidth: 27,
              fontSize: 1,
              textColor: [0, 0, 0],
              fontStyle: "normal",
              halign: "center",
              valign: "top"
            }
          },
          {
            content: "",
            styles: {
              lineWidth: {
                left: 0.1,
                right: 0.1,
              },
              cellPadding: {
                left: 2,
                right: 2,
                top: 1,
                bottom: 1,
              },
              fillColor: [255, 255, 255],
              cellWidth: 'auto',
              fontSize: 1,
              textColor: [0, 0, 0],
              fontStyle: "normal",
              halign: "center",
              valign: "top"
            }
          }
        ]

        if (body.length <= 14) {
          const number = 14 - body.length;
          for (let index = 0; index < number; index++) {
            body.push(emptyBody)
          }
        }

        const endOfPage = [
          {
            content: "",
            colSpan: 6,
            styles: {
              cellWidth: 'auto',
              lineWidth: {
                top: 0.1
              },
              fontSize: 15,
              fillColor: [255, 255, 255],
              textColor: [15, 23, 42],
              cellPadding: 0,
              fontStyle: "normal",
              halign: "center",
              valign: "middle"
            }
          }
        ]
        body.push(endOfPage)

        resolve(body)
      })
    },
    onInitFooter(doc) {
      return new Promise((resolve, reject) => {
        const footer = [];
        const weightTotal = [
          {
            content: `น้ำหนักทั้งหมด = ${parseFloat(
              this.sumOfWeight
            ).toLocaleString("th-TH", {
              minimumFractionDigits: 2,
            })} kg.`,
            colSpan: 7,
            styles: {
              lineWidth: {
                top: 0.1,
              },
              cellWidth: "auto",
              fontSize: 15,
              textColor: [15, 23, 42],
              cellPadding: {
                top: 10,
              },
              fontStyle: "normal",
              halign: "left",
              valign: "middle",
            },
          },
        ];
        const grandTotal = [
          {
            content: `น้ำหนักรวม = ${parseFloat(this.sumOfGross).toLocaleString(
              "th-TH",
              {
                minimumFractionDigits: 2,
              }
            )} kg.`,
            colSpan: 7,
            styles: {
              lineWidth: 0,
              cellWidth: "auto",
              fontSize: 15,
              textColor: [15, 23, 42],
              cellPadding: {
                bottom: 5,
              },
              fontStyle: "normal",
              halign: "left",
              valign: "middle",
            },
          },
        ];
        const transport1 = [
          {
            content:
              "ทะเบียนรถ...................................................",
            colSpan: 7,
            styles: {
              lineWidth: 0,
              cellWidth: "auto",
              fontSize: 15,
              textColor: [15, 23, 42],
              cellPadding: {
                top: 10,
                bottom: 5,
              },
              fontStyle: "normal",
              halign: "left",
              valign: "middle",
            },
          },
        ];
        const transport2 = [
          {
            content:
              "บริษัทขนส่ง..................................................",
            colSpan: 7,
            styles: {
              lineWidth: 0,
              cellWidth: "auto",
              fontSize: 15,
              textColor: [15, 23, 42],
              cellPadding: {
                bottom: 5,
              },
              fontStyle: "normal",
              halign: "left",
              valign: "middle",
            },
          },
        ];
        const transport3 = [
          {
            content:
              "วัน/เดือน/ปี..................................................",
            colSpan: 7,
            styles: {
              lineWidth: 0,
              cellWidth: "auto",
              fontSize: 15,
              textColor: [15, 23, 42],
              cellPadding: {
                bottom: 5,
              },
              fontStyle: "normal",
              halign: "left",
              valign: "middle",
            },
          },
        ];
        const transport4 = [
          {
            content:
              "ผู้ตรวจสอบ..................................................",
            colSpan: 7,
            styles: {
              lineWidth: 0,
              cellWidth: "auto",
              fontSize: 15,
              textColor: [15, 23, 42],
              cellPadding: {
                bottom: 5,
              },
              fontStyle: "normal",
              halign: "left",
              valign: "middle",
            },
          },
        ];

        footer.push(weightTotal);
        footer.push(grandTotal);
        footer.push(transport1);
        footer.push(transport2);
        footer.push(transport3);
        footer.push(transport4);
        resolve(footer);
      });
    },
  }
}
