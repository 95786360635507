import RequestDocument from '@/components/dialogs/request-document-detail'

export default {
  components: {
    RequestDocument
  },
  methods: {
    onInfoClose() {
      this.$router.push("/request")
    }
  }
}